import logo from "./logo.svg";
import "./Game.css";
import React, { useEffect } from "react";
import { Buffer } from "buffer";
import Popup from "reactjs-popup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apikey: null,
      isLoading: false,
      page: null,
    };
  }

  render() {
    return (
      <div className="App min-h-screen mt-auto mb-auto flex flex-col justify-between">
        <div></div>
        <header className="App-header">
          <h1 className="mx-3">404 Not found</h1>
        </header>
        <footer className="mb-3 mt-12 flex justify-center p-3">
          <a
            className="text-base mr-2"
            href="https://hhmc.io/termsampconditionshhmc"
          >
            Terms & Conditions
          </a>
          <Popup
            trigger={
              <p className="button text-base ml-2 cursor-pointer"> Legal </p>
            }
            modal
            nested
          >
            {(close) => (
              <div className="bg-[#1b1e24] mx-20 p-5 max-h-[70vh] overflow-y-auto">
                <div className="text-base mb-2">
                  {" "}
                  Introduction to HHMC staking program to earn TBC tokens{" "}
                </div>
                <div className="text-sm">
                  {" "}
                  HHMC NFT staking is a new way to earn native token TBC. It
                  lets HHMC NFT holders to keep their NFTs in their own wallet
                  and receive rewards for being a hodler. All without the need
                  to sell your NFT collection whilst keeping a full custody of
                  your NFT.
                  <br />
                  At the individual level, NFT staking can benefit collectors as
                  well as the developer team, as the overall supply tends to be
                  lower keeping the collectible price steady. But in a broader
                  context, NFT staking brings a new use case to NFTs that go
                  beyond the idea of collecting a digital art.
                  <br />
                  <br />
                  The minimum point to qualify for TBC token transfer into
                  collector’ own ADA wallet (such as Yoroi, Nami, CCvault/Eternl
                  and or Daedalus) is 50 TBC tokens as the fungible token pay
                  out is a new mint which incurs the associated costs such as
                  minting and gas fees (9 ADA is required and 2 ADA received
                  with TBC token). It is recommended to keep the staking for
                  longer than a month in order to save on such costs. The
                  associated cost is one off should a collector requests for TBC
                  token to be sent into his/her wallet after 7 months period
                  when the whole staking TBC tokens should be distributed to
                  HHMC NFT holders. However it is NFT holder’ responsibility to
                  ensure that staking carried out manually and requesting for
                  TBC token transfer into collector’ own ADA wallet to be done
                  before the program finishes in 7 months’ time.
                  <br />
                  <br />
                  Once staked collector will be able to see the accumulated
                  points on a daily basis as this is recorded in our database.
                  Manual staking (linking your HHMC NFT wallet into our staking
                  webpage) should be carried out at least on a monthly basis in
                  order to qualify for a month worth of points.
                  <br />
                  <br />
                  <p className="inline italic text-sm">Disclaimer:</p> As this
                  is a beta staking program, HHMC team do not guarantee or take
                  responsibility that all the facilities of this program will
                  work flawlessly at the outset. However, we will endeavour our
                  best to deliver high quality user experience as this is rolled
                  out over a period of 3 months in beta. Any loss of staking
                  points and or staking period is not reimbursed due to the
                  volume of HHMC NFTs available for staking and human hours that
                  it takes to look into each such query. Staking does not
                  guarantee any monetary or shareholding value besides native
                  token TBC which will be part of HHMC ecosystem.
                </div>
                <div className="actions mt-4">
                  <button
                    className="p-2"
                    onClick={() => {
                      close();
                    }}
                  >
                    close
                  </button>
                </div>
              </div>
            )}
          </Popup>
        </footer>
      </div>
    );
  }
}

export default withRouter(NotFound);
