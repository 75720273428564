import logo from "./logo.svg";
import "./Admin.css";
import React, { useEffect } from "react";
import { Buffer } from "buffer";
import Popup from "reactjs-popup";

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apikey: null,
      isLoading: false,
      transactionPage: null,
      stakePage: null,
      inputValue: "",
      transactionPageNumber: 1,
      stakePageNumber: 0,
      editTransaction: null,
      editStakeAddress: null,
      inputValueComment: "",
      inputValueState: "",
      listingStake: false,
      stakeAddress: "",
      inputValueTokens: 0,
      inputValueTokenReason: "",
      inputValuePublicTokenReason: "",
    };
  }
  componentDidMount = () => {
    let apikey = localStorage.getItem("apikey");
    if (apikey != "null") {
      this.setState({ inputValue: apikey });
    }
  };
  setVerified = (apikey) => {
    this.setState({
      apikey: apikey,
    });
  };
  update = async (tx_hash, state, comment) => {
    try {
      this.setState({
        isLoading: true,
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          apikey: this.state.apikey,
          tx_hash: tx_hash,
          state: state,
          comment: comment,
        }),
      };
      let result;
      try {
        result = await fetch(
          "https://europe-west1-hhmc-d68d6.cloudfunctions.net/hhmc/payments",
          requestOptions
        );
      } catch (error) {
        alert("Some networking error, please check your connection");
        return;
      }
      if (result.status >= 400 && result.status < 500) {
        alert(await result.text());
        return;
      }
      if (result.status >= 500 && result.status < 600) {
        alert(
          "An unknow error occured, please try again or contact the support"
        );
        return;
      }

      await this.open();
      this.setState({ edit: null });
    } catch (error) {
      alert("An unknow error occured, please try again or contact the support");
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };
  addReward = async (stakeAddress) => {
    try {
      this.setState({
        isLoading: true,
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          apikey: this.state.apikey,
          amount: this.state.inputValueTokens,
          stakeAddress: stakeAddress,
          reason: this.state.inputValueTokenReason,
          publicreason: this.state.inputValuePublicTokenReason,
        }),
      };
      let result;
      try {
        result = await fetch(
          "https://europe-west1-hhmc-d68d6.cloudfunctions.net/hhmc/addreward",
          requestOptions
        );
      } catch (error) {
        alert("Some networking error, please check your connection");
        this.setState({
          isLoading: false,
        });
        return;
      }
      if (result.status >= 400 && result.status < 500) {
        alert(await result.text());
        this.setState({
          isLoading: false,
        });
        return;
      }
      if (result.status >= 500 && result.status < 600) {
        alert(
          "An unknow error occured, please try again or contact the support"
        );
        this.setState({
          isLoading: false,
        });
        return;
      }

      this.setState({ stakeAddress: stakeAddress }, () => {
        this.loadStakeDetails();
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      alert("An unknow error occured, please try again or contact the support");
    } finally {
      this.setState({
        inputValueTokenReason: "",
        inputValuePublicTokenReason: "",
        inputValueTokens: 0,
      });
    }
  };
  deleteReward = async (stakeAddress, ref) => {
    try {
      this.setState({
        isLoading: true,
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          apikey: this.state.apikey,
          stakeAddress: stakeAddress,
          ref: ref,
        }),
      };
      let result;
      try {
        result = await fetch(
          "https://europe-west1-hhmc-d68d6.cloudfunctions.net/hhmc/removereward",
          requestOptions
        );
      } catch (error) {
        alert("Some networking error, please check your connection");
        this.setState({
          isLoading: false,
        });
        return;
      }
      if (result.status >= 400 && result.status < 500) {
        alert(await result.text());
        this.setState({
          isLoading: false,
        });
        return;
      }
      if (result.status >= 500 && result.status < 600) {
        alert(
          "An unknow error occured, please try again or contact the support"
        );
        this.setState({
          isLoading: false,
        });
        return;
      }

      this.setState({ stakeAddress: stakeAddress }, () => {
        this.loadStakeDetails();
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      alert("An unknow error occured, please try again or contact the support");
    } finally {
      this.setState({
        inputValueTokenReason: "",
        inputValueTokens: 0,
      });
    }
  };
  open = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      let result;
      try {
        result = await fetch(
          "https://europe-west1-hhmc-d68d6.cloudfunctions.net/hhmc/payments?" +
            new URLSearchParams({
              apikey: this.state.inputValue,
              page: this.state.transactionPageNumber,
            })
        );
      } catch (error) {
        alert("Some networking error, please check your connection");
        return;
      }
      if (result.status >= 400 && result.status < 500) {
        alert(await result.text());
        return;
      }
      if (result.status >= 500 && result.status < 600) {
        alert(
          "An unknow error occured, please try again or contact the support"
        );
        return;
      }

      var response = await result.json();

      this.setVerified(this.state.inputValue);
      localStorage.setItem("apikey", this.state.inputValue);
      this.setState({
        transactionPage: response,
      });
    } catch (error) {
      alert("An unknow error occured, please try again or contact the support");
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };
  loadStakeDetails = async () => {
    try {
      this.setState({
        isLoading: true,
        listingStake: true,
      });

      let result;
      try {
        result = await fetch(
          "https://europe-west1-hhmc-d68d6.cloudfunctions.net/hhmc/stakeaddressdetails?" +
            new URLSearchParams({
              apikey: this.state.inputValue,
              stakeAddress: this.state.stakeAddress,
            })
        );
      } catch (error) {
        alert("Some networking error, please check your connection");
        this.setState({
          editStakeAddress: null,
        });
        return;
      }
      if (result.status >= 400 && result.status < 500) {
        alert(await result.text());
        this.setState({
          editStakeAddress: null,
        });
        return;
      }
      if (result.status >= 500 && result.status < 600) {
        alert(
          "An unknow error occured, please try again or contact the support"
        );
        this.setState({
          editStakeAddress: null,
        });
        return;
      }

      var response = await result.json();

      this.setVerified(this.state.inputValue);
      localStorage.setItem("apikey", this.state.inputValue);
      console.log(response);
      this.setState({
        editStakeAddress: response,
      });
    } catch (error) {
      alert("An unknow error occured, please try again or contact the support");
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  logout = () => {
    localStorage.setItem("apikey", null);
    this.setState({
      apikey: null,
      isLoading: false,
      transactionPage: null,
      stakePage: null,
      inputValue: "",
      transactionPageNumber: 1,
      stakePageNumber: 0,
      edit: null,
      listingStake: false,
    });
  };
  listStaking = async () => {
    try {
      this.setState({
        isLoading: true,
        listingStake: true,
      });

      let result;
      try {
        result = await fetch(
          "https://europe-west1-hhmc-d68d6.cloudfunctions.net/hhmc/liststaking?" +
            new URLSearchParams({
              apikey: this.state.inputValue,
              page: this.state.stakePageNumber,
            })
        );
      } catch (error) {
        alert("Some networking error, please check your connection");
        return;
      }
      if (result.status >= 400 && result.status < 500) {
        alert(await result.text());
        return;
      }
      if (result.status >= 500 && result.status < 600) {
        alert(
          "An unknow error occured, please try again or contact the support"
        );
        return;
      }

      var response = await result.json();

      this.setVerified(this.state.inputValue);
      localStorage.setItem("apikey", this.state.inputValue);
      console.log(response);
      this.setState({
        stakePage: response,
      });
    } catch (error) {
      alert("An unknow error occured, please try again or contact the support");
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };
  renderDetails = (item, edit) => {
    return (
      <div className="my-2 border-2 text-xs py-3 px-5 ">
        <li key={item.tx_hash} className="list-none">
          {edit !== true ? (
            <div>
              <label>State </label>
              <input
                type={"text"}
                className="text-m my-2 text-black"
                value={this.state.inputValueState}
                onChange={(evt) =>
                  this.setState({ inputValueState: evt.target.value })
                }
              />
            </div>
          ) : (
            <p className="text-m my-2 text-white">Status: {item.state}</p>
          )}
          <br />
          {edit !== true ? (
            <div>
              <label>Comment </label>
              <input
                className="text-m my-2 text-black "
                type={"text"}
                value={this.state.inputValueComment}
                onChange={(evt) =>
                  this.setState({ inputValueComment: evt.target.value })
                }
              />
            </div>
          ) : (
            <p className="text-m my-2 text-white">Comment: {item.comment}</p>
          )}
          <p className="text-xs my-2">
            Transaction time:{" "}
            {new Date(item.timeOfTransaction * 1000).toLocaleString()}
          </p>
          <p className="text-xs my-2">
            Transaction hash:{" "}
            <a
              className="text-blue-500 underline"
              target="blank"
              href={"https://cardanoscan.io/transaction/" + item.tx_hash}
            >
              {item.tx_hash}
            </a>
          </p>

          {item.total.map((total) => {
            return (
              <div
                className={
                  "my-2 border-2 text-xs py-3 px-5 text-white " +
                  (total.isOwnAccount.toString() !== "true"
                    ? "border-slate-600"
                    : "border-green-300")
                }
              >
                <p className="text-s my-2">
                  {total.isOwnAccount.toString() !== "true"
                    ? "This is an external account"
                    : "This is the pool account"}
                </p>
                <a
                  className="text-xs my-2 text-blue-500 underline"
                  target="blank"
                  href={"https://pool.pm/" + total.stakeAddress}
                >
                  {total.stakeAddress}
                </a>
                {total.isOwnAccount.toString() !== "true" ? (
                  <p className="text-xs my-2">{total.paymentAddress}</p>
                ) : (
                  <div></div>
                )}

                {total.isOwnAccount.toString() !== "true" &&
                total.loginTime != null ? (
                  <p className="text-sm text-green-500">
                    Last login to the game{" "}
                    {new Date(total.loginTime).toLocaleString()} from ip:{" "}
                    {total.loginIp !== null ? total.loginIp : "unknown"}
                  </p>
                ) : total.isOwnAccount.toString() !== "true" ? (
                  <p className="text-sm text-red-500">
                    This account did not log into the game yet
                  </p>
                ) : (
                  <div></div>
                )}

                <div className="mt-5">
                  {total.transactionResult.map((tr) => {
                    return (
                      <div className="text-xs my-2">
                        {tr.unit === "lovelace" ? (
                          <p>
                            {tr.amount > 0
                              ? "+" + tr.amount / 1000000
                              : tr.amount / 1000000}{" "}
                            ADA
                          </p>
                        ) : (
                          <p className="text-xs">
                            {tr.amount > 0 ? "+" + tr.amount : tr.amount} other
                            asset{" "}
                            <a
                              className="text-blue-500 underline text-xs"
                              target="_blank"
                              href={"https://www.jpg.store/asset/" + tr.unit}
                            >
                              {" "}
                              {tr.unit}
                            </a>
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </li>
        {edit === true ? (
          <button
            onClick={() => {
              this.setState({
                edit: item,
                inputValueComment: item.comment != null ? item.comment : "",
                inputValueState: item.state,
              });
            }}
          >
            Update
          </button>
        ) : (
          <div>
            <button
              onClick={() => {
                //TODO save
                this.update(
                  item.tx_hash,
                  this.state.inputValueState,
                  this.state.inputValueComment
                );
              }}
            >
              Save
            </button>
            <button onClick={() => this.setState({ edit: null })}>
              Cancel
            </button>
          </div>
        )}
      </div>
    );
  };
  secondsToPoints(seconds) {
    //2592000
    seconds = Number(seconds);
    return Math.round(seconds * 0.00578703703);
  }
  render() {
    return (
      <div className=" min-h-screen mt-auto mb-auto flex flex-col justify-between">
        <div></div>
        <header className="App-header">
          {this.state.isLoading ? (
            <div className="lds-dual-ring flex-shrink justify-center "></div>
          ) : this.state.listingStake === true ? (
            <div>
              <div className="flex flex-row justify-between items-center">
                <h1 className="text-3xl mr-2">Stake Addresses</h1>
                <div>
                  <input
                    type={"text"}
                    className="text-m my-2 text-black mx-1"
                    value={this.state.stakeAddress}
                    onChange={(evt) => {
                      this.setState({ stakeAddress: evt.target.value });
                      if (evt.target.value === "") {
                        this.setState({ editStakeAddress: null });
                      }
                    }}
                  />
                </div>
                <button
                  className="ml-1"
                  onClick={() => {
                    this.loadStakeDetails();
                  }}
                >
                  Search
                </button>
                <button
                  onClick={() => {
                    this.setState({ listingStake: false });
                  }}
                >
                  List Transactions
                </button>
                <button onClick={this.logout}>Logout</button>
              </div>
              {this.state.editStakeAddress != null ? (
                <div>
                  {/* assetsCurrentlyStaking: stakeabelAssets,
              rewards: [], */}
                  <p> {this.state.editStakeAddress.stakeAddress}</p>
                  <p> {this.state.editStakeAddress.beach32Address}</p>
                  <p>
                    {" "}
                    {"The user has points equal to: " +
                      Math.floor(
                        this.secondsToPoints(
                          this.state.editStakeAddress.time / 1000
                        ) / 100.0
                      ) +
                      " tokens"}
                  </p>
                  {this.state.editStakeAddress.canDoPayout == false ? (
                    <p className="text-red-500">
                      {"The user can not perform a payout due to:  " +
                        this.state.editStakeAddress.message}
                    </p>
                  ) : (
                    <p className="text-green-500">
                      The user can perform a payout
                    </p>
                  )}
                  <br />
                  <p>Currently staking the following assets</p>
                  <br />
                  <ul>
                    {this.state.editStakeAddress.assetsCurrentlyStaking.map(
                      (item) => {
                        return (
                          <li className="my-2 border-2 text-xs py-3 px-5 list-none">
                            <a
                              className="text-blue-500 underline text-s"
                              target="_blank"
                              href={"https://www.jpg.store/asset/" + item.unit}
                            >
                              {"staking " +
                                item.quantity +
                                " assets with id " +
                                item.unit}
                            </a>
                          </li>
                        );
                      }
                    )}
                  </ul>
                  <br />
                  <p>The user has the following rewards:</p>
                  <br />
                  {this.state.editStakeAddress.rewards.length === 0 ? (
                    <p>No rewards so far</p>
                  ) : (
                    <div></div>
                  )}
                  <ul className="mt-5">
                    {this.state.editStakeAddress.rewards.map((item) => {
                      return (
                        <li className="my-2 border-2 text-xs py-3 px-5 list-none">
                          <p className="my-1">
                            {"Reward of " +
                              Math.round(
                                this.secondsToPoints(item.amount / 1000) / 100.0
                              ) +
                              " tokens for: " +
                              item.reason +
                              "with the user visible explaination " +
                              item.publicreason}
                          </p>
                          <br />
                          <button
                            className="my-3 "
                            onClick={() => {
                              this.deleteReward(
                                this.state.editStakeAddress.stakeAddress,
                                item.ref
                              );
                            }}
                          >
                            Delete this reward
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                  <div>
                    <div>
                      <label>Tokens </label>
                      <input
                        className="text-m my-2 text-black "
                        type={"number"}
                        value={this.state.inputValueTokens}
                        onChange={(evt) =>
                          this.setState({ inputValueTokens: evt.target.value })
                        }
                      />
                    </div>
                    <div>
                      <label>Admin visible reason </label>
                      <input
                        className="text-m my-2 text-black "
                        type={"text"}
                        value={this.state.inputValueTokenReason}
                        onChange={(evt) =>
                          this.setState({
                            inputValueTokenReason: evt.target.value,
                          })
                        }
                      />
                    </div>
                    <div>
                      <label>
                        User visible text (please include token amount visible
                        to user){" "}
                      </label>
                      <input
                        className="text-m my-2 text-black "
                        type={"text"}
                        value={this.state.inputValuePublicTokenReason}
                        onChange={(evt) =>
                          this.setState({
                            inputValuePublicTokenReason: evt.target.value,
                          })
                        }
                      />
                    </div>
                    <button
                      onClick={() => {
                        this.addReward(
                          this.state.editStakeAddress.stakeAddress
                        );
                      }}
                    >
                      Add reward
                    </button>
                  </div>
                  <button
                    onClick={() => this.setState({ editStakeAddress: null })}
                  >
                    Close
                  </button>
                </div>
              ) : this.state.stakePage.length == 0 ? (
                <div className="min-h-[600px]">
                  <p>No Stake addresses found</p>
                </div>
              ) : (
                <div>
                  <ul>
                    {this.state.stakePage.map((item) => {
                      return (
                        <li className="my-2 border-2 text-xs py-3 px-5 list-none">
                          <p className="my-1"> {item}</p>
                          <a
                            className="text-blue-500 underline text-xs my-1"
                            target="blank"
                            href={"https://pool.pm/" + item}
                          >
                            Open on pool.pm
                          </a>
                          <br />
                          <button
                            className="my-3 "
                            onClick={() => {
                              this.setState({ stakeAddress: item }, () => {
                                this.loadStakeDetails();
                              });
                            }}
                          >
                            Details
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="flex flex-row justify-between items-center">
                    <button
                      disabled={
                        this.state.stakePage == null ||
                        this.state.stakePageNumber <= 1
                      }
                      onClick={() => {
                        this.setState({
                          stakePageNumber: this.state.stakePageNumber - 1,
                        });
                        this.listStaking();
                      }}
                    >
                      Previous page
                    </button>
                    <p className="text-sm text-white">
                      {this.state.stakePageNumber}
                    </p>
                    <button
                      disabled={
                        this.state.stakePage == null ||
                        this.state.stakePageNumber.length < 10
                      }
                      onClick={() => {
                        this.setState({
                          stakePageNumber: this.state.stakePageNumber + 1,
                        });
                        this.listStaking();
                      }}
                    >
                      Next page
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : this.state.editTransaction !== null ? (
            <div className="my-5">
              <div className="flex flex-row justify-between">
                <h1 className="text-3xl">Edit Transaction</h1>
                <button onClick={this.logout}>Logout</button>
              </div>
              {this.renderDetails(this.state.editTransaction, false)}
            </div>
          ) : this.state.transactionPage ? (
            <div className="my-5">
              <div className="flex flex-row justify-between items-center">
                <h1 className="text-3xl">Transactions</h1>
                <button onClick={this.listStaking}>List Stake Addresses</button>
                <button onClick={this.logout}>Logout</button>
              </div>
              {this.state.transactionPage.length == 0 ? (
                <div className="min-h-[600px]">
                  <p>No transactions found</p>
                </div>
              ) : (
                <div></div>
              )}
              <ul>
                {this.state.transactionPage.map((item) => {
                  return this.renderDetails(item, true);
                })}
              </ul>
              <div className="flex flex-row justify-between items-center">
                <button
                  disabled={
                    this.state.transactionPage == null ||
                    this.state.transactionPageNumber <= 1
                  }
                  onClick={() => {
                    this.setState({
                      transactionPageNumber:
                        this.state.transactionPageNumber - 1,
                    });
                    this.open();
                  }}
                >
                  Previous page
                </button>
                <p className="text-sm text-white">
                  {this.state.transactionPageNumber}
                </p>
                <button
                  disabled={
                    this.state.transactionPage == null ||
                    this.state.transactionPage.length < 10
                  }
                  onClick={() => {
                    this.setState({
                      transactionPageNumber:
                        this.state.transactionPageNumber + 1,
                    });
                    this.open();
                  }}
                >
                  Next page
                </button>
              </div>
            </div>
          ) : (
            <div>
              <p className="m-3 text-xl">Please enter you API Key</p>
              <input
                type={"password"}
                value={this.state.inputValue}
                onChange={(evt) =>
                  this.setState({ inputValue: evt.target.value })
                }
              />
              <button className="ml-3 px-3" onClick={this.open}>
                Login
              </button>
            </div>
          )}
        </header>
        <footer className="mb-3 mt-12 flex justify-center p-3">
          <a
            className="text-base mr-2"
            href="https://hhmc.io/termsampconditionshhmc"
          >
            Terms & Conditions
          </a>
          <Popup
            trigger={
              <p className="button text-base ml-2 cursor-pointer"> Legal </p>
            }
            modal
            nested
          >
            {(close) => (
              <div className="bg-[#1b1e24] mx-20 p-5 max-h-[70vh] overflow-y-auto">
                <div className="text-base mb-2">
                  {" "}
                  Introduction to HHMC staking program to earn TBC tokens{" "}
                </div>
                <div className="text-sm">
                  {" "}
                  HHMC NFT staking is a new way to earn native token TBC. It
                  lets HHMC NFT holders to keep their NFTs in their own wallet
                  and receive rewards for being a hodler. All without the need
                  to sell your NFT collection whilst keeping a full custody of
                  your NFT.
                  <br />
                  At the individual level, NFT staking can benefit collectors as
                  well as the developer team, as the overall supply tends to be
                  lower keeping the collectible price steady. But in a broader
                  context, NFT staking brings a new use case to NFTs that go
                  beyond the idea of collecting a digital art.
                  <br />
                  <br />
                  The minimum point to qualify for TBC token transfer into
                  collector’ own ADA wallet (such as Yoroi, Nami, CCvault/Eternl
                  and or Daedalus) is 50 TBC tokens as the fungible token pay
                  out is a new mint which incurs the associated costs such as
                  minting and gas fees (9 ADA is required and 2 ADA received
                  with TBC token). It is recommended to keep the staking for
                  longer than a month in order to save on such costs. The
                  associated cost is one off should a collector requests for TBC
                  token to be sent into his/her wallet after 7 months period
                  when the whole staking TBC tokens should be distributed to
                  HHMC NFT holders. However it is NFT holder’ responsibility to
                  ensure that staking carried out manually and requesting for
                  TBC token transfer into collector’ own ADA wallet to be done
                  before the program finishes in 7 months’ time.
                  <br />
                  <br />
                  Once staked collector will be able to see the accumulated
                  points on a daily basis as this is recorded in our database.
                  Manual staking (linking your HHMC NFT wallet into our staking
                  webpage) should be carried out at least on a monthly basis in
                  order to qualify for a month worth of points.
                  <br />
                  <br />
                  <p className="inline italic text-sm">Disclaimer:</p> As this
                  is a beta staking program, HHMC team do not guarantee or take
                  responsibility that all the facilities of this program will
                  work flawlessly at the outset. However, we will endeavour our
                  best to deliver high quality user experience as this is rolled
                  out over a period of 3 months in beta. Any loss of staking
                  points and or staking period is not reimbursed due to the
                  volume of HHMC NFTs available for staking and human hours that
                  it takes to look into each such query. Staking does not
                  guarantee any monetary or shareholding value besides native
                  token TBC which will be part of HHMC ecosystem.
                </div>
                <div className="actions mt-4">
                  <button
                    className="p-2"
                    onClick={() => {
                      close();
                    }}
                  >
                    close
                  </button>
                </div>
              </div>
            )}
          </Popup>
        </footer>
      </div>
    );
  }
}

export default Admin;
