var cardanoWasm = null;

export const wasmLoader = async () => {
  if (cardanoWasm) {
    return cardanoWasm;
  }

  cardanoWasm = await import(
    "@emurgo/cardano-serialization-lib-browser/cardano_serialization_lib"
  );
  return cardanoWasm;
};
