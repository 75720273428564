import logo from "./logo.svg";
import "./App.css";
import React, { useEffect } from "react";
import { Buffer } from "buffer";
import Web3Token from "web3-cardano-token/dist/browser";
import Popup from "reactjs-popup";
import { wasmLoader } from "./wasmLoader";
import {
  BigNum,
  LinearFee,
  TransactionBuilder,
  Address,
  TransactionBuilderConfigBuilder,
  TransactionOutputBuilder,
  TransactionUnspentOutput,
  Value,
  Transaction,
  TransactionWitnessSet,
  TransactionUnspentOutputs,
} from "@emurgo/cardano-serialization-lib-browser/cardano_serialization_lib";

const toHex = require("to-hex");

export default class App extends React.Component {
  protocolParams = {
    linearFee: {
      minFeeA: "44",
      minFeeB: "155381",
    },
    minUtxo: "34482",
    poolDeposit: "500000000",
    keyDeposit: "2000000",
    maxValSize: 5000,
    maxTxSize: 16384,
    priceMem: 0.0577,
    priceStep: 0.0000721,
    coinsPerUtxoWord: "34482",
  };
  constructor(props) {
    super(props);
    this.state = {
      payoutIsPublic: true,
      isVerified: false,
      walletCount: 0,
      nami: false,
      ccvault: false,
      gero: false,
      typhoon: false,
      flint: false,
      card: false,
      isLoading: true,
      canDoPayout: false,
      errormessage: "",
      signature: null,
      uid: null,
      paymentAddress: null,
      adaFee: null,
      selectedTokenCount: 0,
      rewards: [],
    };
    // this.setVerified=this.setVerified.bind(this)
  }
  cardanoWasm = null;
  initTransactionBuilder = async () => {
    this.cardanoWasm = await wasmLoader();
    const txBuilder = TransactionBuilder.new(
      TransactionBuilderConfigBuilder.new()
        .fee_algo(
          LinearFee.new(
            BigNum.from_str(this.protocolParams.linearFee.minFeeA),
            BigNum.from_str(this.protocolParams.linearFee.minFeeB)
          )
        )
        .pool_deposit(BigNum.from_str(this.protocolParams.poolDeposit))
        .key_deposit(BigNum.from_str(this.protocolParams.keyDeposit))
        .coins_per_utxo_word(
          BigNum.from_str(this.protocolParams.coinsPerUtxoWord)
        )
        .max_value_size(this.protocolParams.maxValSize)
        .max_tx_size(this.protocolParams.maxTxSize)
        .prefer_pure_change(true)
        .build()
    );

    return txBuilder;
  };
  minPoints = 15000;
  setVerified = (
    assets,
    time,
    canDoPayout,
    message,
    rewards,
    signature,
    uid
  ) => {
    var count = 0;
    assets.forEach((element) => {
      count += element.factor ?? 1;
    });
    this.setState({
      isVerified: true,
      count: count,
      time: time,
      isLoading: false,
      canDoPayout: canDoPayout,
      errormessage: message,
      signature: signature,
      uid: uid,
      rewards: rewards,
      selectedTokenCount: Math.floor(
        this.secondsToPoints(this.state.time / 1000) / 100.0
      ),
    });
  };
  namiIsAvailable = () => {
    // @ts-ignore
    let res =
      typeof window.cardano !== "undefined" &&
      (typeof window.cardano.nami !== "undefined" ||
        typeof window.cardano.enable !== "undefined");
    if (res) {
      let state = this.state;
      this.setState({
        walletCount: state.walletCount + 1,
        nami: true,
        isLoading: false,
      });
    }
    return res;
  };

  namiIsConnected = async () => {
    if (await this.isAvailable()) {
      // @ts-ignore
      if (typeof window.cardano.nami !== "undefined") {
        // @ts-ignore
        return await window.cardano.nami.isEnabled();
      } else {
        console.debug(`Using legacy nami wallet integration`);

        // @ts-ignore
        return await window.cardano.isEnabled();
      }
    } else {
      return false;
    }
  };

  connectNami = async () => {
    // @ts-ignore
    if (typeof window.cardano.nami !== "undefined") {
      // @ts-ignore
      return await window.cardano.nami.enable();
    } else {
      console.debug(`Using legacy nami wallet integration`);

      // @ts-ignore
      await window.cardano.enable();

      // @ts-ignore
      return window.cardano;
    }
  };
  ccVaultIsAvailable = () => {
    // @ts-ignore
    let res =
      typeof window.cardano !== "undefined" &&
      typeof window.cardano.eternl !== "undefined";
    if (res) {
      let state = this.state;
      this.setState({
        walletCount: state.walletCount + 1,
        ccvault: true,
        isLoading: false,
      });
    }
    return res;
  };

  ccVaultIsConnected = async () => {
    // @ts-ignore

    return (
      (await this.isAvailable()) && (await window.cardano.eternl.isEnabled())
    );
  };

  connectCCVault = async () => {
    // @ts-ignore
    const cip30 = await window.cardano.eternl.enable();

    return cip30;
  };
  cardano = null;
  gerowalletisAvailable = () => {
    // @ts-ignore
    let res =
      typeof window.cardano !== "undefined" &&
      typeof window.cardano.gerowallet !== "undefined";
    if (res) {
      let state = this.state;
      this.setState({
        walletCount: state.walletCount + 1,
        gero: true,
        isLoading: false,
      });
    }
    return res;
  };

  gerowalletisConnected = async () => {
    // @ts-ignore
    return (
      (await this.isAvailable()) &&
      (await window.cardano.gerowallet.isEnabled())
    );
  };
  connectgerowallet = async () => {
    // @ts-ignore
    const cip30 = await window.cardano.gerowallet.enable();

    return cip30;
  };
  typhonisAvailable = () => {
    // @ts-ignore
    let res =
      typeof window.cardano !== "undefined" &&
      typeof window.cardano.typhon !== "undefined";
    if (res) {
      let state = this.state;
      this.setState({
        walletCount: state.walletCount + 1,
        typhoon: true,
        isLoading: false,
      });
    }
    return res;
  };

  typhonisConnected = async () => {
    // @ts-ignore
    if (await this.isAvailable()) {
      // @ts-ignore
      const isEnabledResponse = await window.cardano.typhon.isEnabled();

      return (
        isEnabledResponse.status === true && isEnabledResponse.data === true
      );
    }

    return false;
  };

  connecttyphon = async () => {
    // @ts-ignore
    const enableResponse = await window.cardano.typhon.enable();

    if (enableResponse.status === false || enableResponse.data === false) {
      throw new Error("Could not enabled Typhon Wallet");
    }

    // @ts-ignore
    return window.cardano.typhon;
  };
  flintIsAvailable = () => {
    // @ts-ignore
    let res =
      typeof window.cardano !== "undefined" &&
      typeof window.cardano.flint !== "undefined";
    if (res) {
      let state = this.state;
      this.setState({
        walletCount: state.walletCount + 1,
        flint: true,
        isLoading: false,
      });
    }
    return res;
  };

  flintIsConnected = async () => {
    // @ts-ignore
    return (
      (await this.isAvailable()) && (await window.cardano.flint.isEnabled())
    );
  };
  connectFlint = async () => {
    // @ts-ignore
    return await window.cardano.flint.enable();
  };
  cardWalletIsAvailable = () => {
    // @ts-ignore
    let res = typeof window.cardwallet !== "undefined";
    if (res) {
      let state = this.state;
      this.setState({
        walletCount: state.walletCount + 1,
        card: true,
        isLoading: false,
      });
    }
    return res;
  };

  cardWalletIsConnected = async () => {
    // @ts-ignore
    return (await this.isAvailable()) && (await window.cardwallet.isEnabled());
  };

  connectCardWallet = async () => {
    // @ts-ignore
    await window.cardwallet.enable();

    // @ts-ignore
    return window.cardwallet;
  };

  openCardWallet = async () => {
    this.cardano = await this.connectCardWallet();
    this.open(this.cardano);
  };
  openFlint = async () => {
    this.cardano = await this.connectFlint();
    this.open(this.cardano);
  };
  openTyphoon = async () => {
    this.cardano = await this.connecttyphon();
    this.open(this.cardano);
  };
  openGero = async () => {
    this.cardano = await this.connectgerowallet();
    this.open(this.cardano);
  };
  openNami = async () => {
    this.cardano = await this.connectNami();
    this.open(this.cardano);
  };
  openCCVault = async () => {
    this.cardano = await this.connectCCVault();
    this.open(this.cardano);
  };
  secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
    var mDisplay = m + (m == 1 ? " minute " : " minutes ");
    var sDisplay = s + (s == 1 ? " second" : " seconds");
    return dDisplay + hDisplay + mDisplay + sDisplay;
  }
  secondsToPoints(seconds) {
    //2592000
    seconds = Number(seconds);
    return Math.floor(seconds * 0.00578703703);
  }
  NewlineText(props) {
    const text = props.text;
    const newText = text.split("\r\n").map((str) => <p>{str}</p>);

    return newText;
  }
  payNow = async () => {
    let txBuilder = await this.initTransactionBuilder();
    var utxos = await this.cardano.getUtxos();
    var addresses = await this.cardano.getUsedAddresses();
    console.log(addresses);
    const shelleyOutputAddress = Address.from_bech32(this.state.paymentAddress);
    const shelleyChangeAddress = Address.from_hex(addresses[0]);

    txBuilder.add_output(
      TransactionOutputBuilder.new()
        .with_address(shelleyOutputAddress)
        .next()
        .with_value(Value.new(BigNum.from_str("9000000")))
        .build()
    );

    // convert utxos from wallet connector
    const utxosFromWalletConnector = utxos.map((utxo) =>
      TransactionUnspentOutput.from_bytes(Buffer.from(utxo, "hex"))
    );

    // create TransactionUnspentOutputs for 'add_inputs_from' function
    const utxoOutputs = TransactionUnspentOutputs.new();
    utxosFromWalletConnector.map((currentUtxo) => {
      utxoOutputs.add(currentUtxo);
    });

    // inputs with coin selection
    // 0 for LargestFirst, 1 RandomImprove 2,3 Mutli asset
    txBuilder.add_inputs_from(utxoOutputs, 0);
    txBuilder.add_change_if_needed(shelleyChangeAddress);

    const txBody = txBuilder.build();
    const transaction = Transaction.new(
      txBuilder.build(),
      TransactionWitnessSet.new()
    );
    try {
      const witness = await this.cardano.signTx(
        Buffer.from(transaction.to_bytes(), "hex").toString("hex")
      );

      const signedTx = Transaction.new(
        txBody,
        TransactionWitnessSet.from_bytes(Buffer.from(witness, "hex")),
        undefined // transaction metadata
      );
      console.log(signedTx.to_json());
      this.cardano.submitTx(
        Buffer.from(signedTx.to_bytes(), "utf8").toString("hex")
      );
      this.setState({
        isLoading: true,
      });
      alert(
        "Transaction submitted, please wait and check your wallet in a few minutes"
      );
    } catch (error) {}
  };
  startPayout = async (tokens) => {
    const value = document.getElementById("payout").value;
    if (value < 150) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    try {
      let requestOptions;
      if (this.state.signature.signature && this.state.signature.key) {
        requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            uid: this.state.uid,
            amount: value,
            signature: this.state.signature.signature,
            key: this.state.signature.key,
          }),
        };
      } else {
        requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            uid: this.state.uid,
            amount: value,
            signature: this.state.signature,
          }),
        };
      }

      let result;
      try {
        result = await fetch(
          "https://europe-west1-hhmc-d68d6.cloudfunctions.net/hhmc/payout",
          requestOptions
        );
      } catch (error) {
        alert("Some networking error, please check your connection");
        return;
      }
      if (result.status >= 400 && result.status < 500) {
        alert(await result.text());
        return;
      }
      if (result.status >= 500 && result.status < 600) {
        alert(
          "An unknow error occured, please try again or contact the support"
        );
        return;
      }

      var response = await result.json();
      this.setState({
        adaFee: response.adaToPay,
        paymentAddress: response.paymentAddress,
      });
    } catch (error) {
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };
  open = async (cardano) => {
    try {
      this.setState({
        isLoading: true,
      });
      // getting address from which we will sign message
      const address = (await cardano.getUsedAddresses())[0];

      console.log(address);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          address: address,
          message:
            "Please verify your wallet to show you belong to the HHMC community ",
        }),
      };
      let result;
      try {
        result = await fetch(
          "https://europe-west1-hhmc-d68d6.cloudfunctions.net/hhmc/auth",
          requestOptions
        );
      } catch (error) {
        alert("Some networking error, please check your connection");
        return;
      }
      if (result.status >= 400 && result.status < 500) {
        alert(await result.text());
        return;
      }
      if (result.status >= 500 && result.status < 600) {
        alert(
          "An unknow error occured, please try again or contact the support"
        );
        return;
      }

      var response = await result.json();
      const messageToSign = Buffer.from(response.message, "utf8").toString(
        "hex"
      );
      console.log(response.message);
      let token;
      try {
        token = await cardano.signData(address, messageToSign);
      } catch (error) {
        return;
      }
      let requestOptions2;
      if (token.signature && token.key) {
        requestOptions2 = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            uid: response.uid,
            signature: token.signature,
            key: token.key,
          }),
        };
      } else {
        requestOptions2 = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ uid: response.uid, signature: token }),
        };
      }
      let result2;
      try {
        result2 = await fetch(
          "https://europe-west1-hhmc-d68d6.cloudfunctions.net/hhmc/verify",
          requestOptions2
        );
      } catch (error) {
        alert("Some networking error, please check your connection");
        return;
      }
      if (result.status >= 400 && result.status < 500) {
        alert(await result.text());
        return;
      }
      if (result.status >= 500 && result.status < 600) {
        alert(
          "An unknow error occured, please try again or contact the support"
        );
        return;
      }
      var response2 = await result2.json();
      //(assets, time, canDoPayout, message, signature, uid)
      this.setVerified(
        response2.assetsCurrentlyStaking,
        response2.time,
        response2.canDoPayout,
        response2.message,
        response2.rewards,
        token,
        response.uid
      );
    } catch (error) {
      alert("An unknow error occured, please try again or contact the support");
    } finally {
      this.setState({
        isLoading: false,
      });
    }
    /*const */
  };
  componentDidMount() {
    window.addEventListener("load", () => {
      this.ccVaultIsAvailable();
      this.namiIsAvailable();
      this.cardWalletIsAvailable();
      this.flintIsAvailable();
      this.typhonisAvailable();
      this.gerowalletisAvailable();
      this.setState({ isLoading: false });
    });
  }
  render() {
    if (this.state.paymentAddress) {
      return (
        <div className="App min-h-screen mt-auto mb-auto flex flex-col justify-between">
          <div></div>
          <header className="App-header">
            {this.state.isLoading ? (
              <div class="lds-dual-ring flex-shrink justify-center "></div>
            ) : (
              <>
                <h2>
                  To make a payout send {this.state.adaFee}ADA to the following
                  address
                </h2>
                <p className="select-all break-all">
                  {this.state.paymentAddress}
                </p>
                <p>or</p>
                <button
                  onClick={this.payNow}
                  className="  p-4 py-2 rounded-full "
                >
                  Pay with Wallet
                </button>
              </>
            )}
          </header>
          <footer className="mb-3 mt-12 flex justify-center p-3">
            <a
              className="text-base mr-2"
              href="https://hhmc.io/termsampconditionshhmc"
            >
              Terms & Conditions
            </a>
            <Popup
              trigger={
                <p className="button text-base ml-2 cursor-pointer"> Legal </p>
              }
              modal
              nested
            >
              {(close) => (
                <div className="bg-[#1b1e24] mx-20 p-5 max-h-[70vh] overflow-y-auto">
                  <div className="text-base mb-2">
                    {" "}
                    Introduction to HHMC staking program to earn TBC tokens{" "}
                  </div>
                  <div className="text-sm">
                    {" "}
                    HHMC NFT staking is a new way to earn native token TBC. It
                    lets HHMC NFT holders to keep their NFTs in their own wallet
                    and receive rewards for being a hodler. All without the need
                    to sell your NFT collection whilst keeping a full custody of
                    your NFT.
                    <br />
                    At the individual level, NFT staking can benefit collectors
                    as well as the developer team, as the overall supply tends
                    to be lower keeping the collectible price steady. But in a
                    broader context, NFT staking brings a new use case to NFTs
                    that go beyond the idea of collecting a digital art.
                    <br />
                    <br />
                    The minimum point to qualify for TBC token transfer into
                    collector’ own ADA wallet (such as Yoroi, Nami,
                    CCvault/Eternl and or Daedalus) is 50 TBC tokens as the
                    fungible token pay out is a new mint which incurs the
                    associated costs such as minting and gas fees (9 ADA is
                    required and 2 ADA received with TBC token). It is
                    recommended to keep the staking for longer than a month in
                    order to save on such costs. The associated cost is one off
                    should a collector requests for TBC token to be sent into
                    his/her wallet after 7 months period when the whole staking
                    TBC tokens should be distributed to HHMC NFT holders.
                    However it is NFT holder’ responsibility to ensure that
                    staking carried out manually and requesting for TBC token
                    transfer into collector’ own ADA wallet to be done before
                    the program finishes in 7 months’ time.
                    <br />
                    <br />
                    Once staked collector will be able to see the accumulated
                    points on a daily basis as this is recorded in our database.
                    Manual staking (linking your HHMC NFT wallet into our
                    staking webpage) should be carried out at least on a monthly
                    basis in order to qualify for a month worth of points.
                    <br />
                    <br />
                    <p className="inline italic text-sm">Disclaimer:</p> As this
                    is a beta staking program, HHMC team do not guarantee or
                    take responsibility that all the facilities of this program
                    will work flawlessly at the outset. However, we will
                    endeavour our best to deliver high quality user experience
                    as this is rolled out over a period of 3 months in beta. Any
                    loss of staking points and or staking period is not
                    reimbursed due to the volume of HHMC NFTs available for
                    staking and human hours that it takes to look into each such
                    query. Staking does not guarantee any monetary or
                    shareholding value besides native token TBC which will be
                    part of HHMC ecosystem.
                  </div>
                  <div className="actions mt-4">
                    <button
                      className="p-2"
                      onClick={() => {
                        close();
                      }}
                    >
                      close
                    </button>
                  </div>
                </div>
              )}
            </Popup>
          </footer>
        </div>
      );
    } else if (this.state.isVerified) {
      return (
        <div className="App min-h-screen mt-auto mb-auto flex flex-col justify-between">
          <div></div>
          <header className="App-header">
            {this.state.isLoading ? (
              <div class="lds-dual-ring flex-shrink justify-center "></div>
            ) : (
              <>
                <h2>Your are now staking {this.state.count} assets</h2>
                <p>
                  Your total staking time is
                  {" " + this.secondsToDhms(this.state.time / 1000)} or{" "}
                  {this.secondsToPoints(
                    this.state.time / 1000
                  ).toLocaleString()}{" "}
                  points
                </p>
                <div>
                  <br />
                  <p>Your rewards are: </p>
                  <ul>
                    {this.state.rewards.map((item) => {
                      return (
                        <li className="my-2 border-2 text-xs py-3 px-5 list-none">
                          <p className="my-1"> {item.publicreason}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <p className="text-sm my-2">
                  <br />
                  Exchange 100 points for 1 TBC tokens (payout fee is 9 ADA flat
                  with 2 ADA refunded)
                </p>
                {Math.floor(
                  this.secondsToPoints(this.state.time / 1000) / 100.0
                ) >= 150 && this.state.payoutIsPublic ? (
                  <>
                    {!this.state.canDoPayout && (
                      <p className="text-sm my-2">
                        <br />

                        {this.state.errormessage}
                      </p>
                    )}
                    <div>
                      <input
                        id="payout"
                        className="p-4 py-2 text-gray-700 invalid:bg-red-700  no-right-border"
                        type="number"
                        min="150"
                        max={Math.max(
                          150,
                          Math.floor(
                            this.secondsToPoints(this.state.time / 1000) / 100.0
                          )
                        )}
                        onChange={(evt) => {
                          this.setState({
                            selectedTokenCount: evt.target.value,
                          });
                        }}
                        defaultValue={Math.floor(
                          this.secondsToPoints(this.state.time / 1000) / 100.0
                        )}
                        disabled={!this.state.canDoPayout}
                      ></input>
                      <button
                        onClick={this.startPayout}
                        disabled={
                          !this.state.canDoPayout ||
                          this.state.selectedTokenCount >
                            Math.floor(
                              this.secondsToPoints(this.state.time / 1000) /
                                100.0
                            ) ||
                          this.state.selectedTokenCount < 150
                        }
                        className="special p-4 py-2"
                      >
                        Request a Payout
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <p className="text-sm my-2">
                      <br />
                      The minimum payout is 150 Tokens (
                      {this.minPoints.toLocaleString()} points)
                    </p>
                    {!this.state.canDoPayout && (
                      <p className="text-sm my-2">
                        <br />

                        {this.state.errormessage}
                      </p>
                    )}
                    <input
                      disabled
                      className="p-4 py-2 text-gray-700 invalid:bg-red-700  no-right-border"
                      type="number"
                      min="150"
                      max="150"
                      defaultValue={Math.floor(
                        this.secondsToPoints(this.state.time / 1000) / 100.0
                      )}
                    ></input>
                    <button
                      disabled={!this.state.canDoPayout}
                      className="special"
                      disabled={true}
                    >
                      {this.state.payoutIsPublic
                        ? "Request a Payout"
                        : "Payout coming soon"}
                    </button>
                  </>
                )}
              </>
            )}
          </header>
          <footer className="mb-3 mt-12 flex justify-center p-3">
            <a
              className="text-base mr-2"
              href="https://hhmc.io/termsampconditionshhmc"
            >
              Terms & Conditions
            </a>
            <Popup
              trigger={
                <p className="button text-base ml-2 cursor-pointer"> Legal </p>
              }
              modal
              nested
            >
              {(close) => (
                <div className="bg-[#1b1e24] mx-20 p-5 max-h-[70vh] overflow-y-auto">
                  <div className="text-base mb-2">
                    {" "}
                    Introduction to HHMC staking program to earn TBC tokens{" "}
                  </div>
                  <div className="text-sm">
                    {" "}
                    HHMC NFT staking is a new way to earn native token TBC. It
                    lets HHMC NFT holders to keep their NFTs in their own wallet
                    and receive rewards for being a hodler. All without the need
                    to sell your NFT collection whilst keeping a full custody of
                    your NFT.
                    <br />
                    At the individual level, NFT staking can benefit collectors
                    as well as the developer team, as the overall supply tends
                    to be lower keeping the collectible price steady. But in a
                    broader context, NFT staking brings a new use case to NFTs
                    that go beyond the idea of collecting a digital art.
                    <br />
                    <br />
                    The minimum point to qualify for TBC token transfer into
                    collector’ own ADA wallet (such as Yoroi, Nami,
                    CCvault/Eternl and or Daedalus) is 50 TBC tokens as the
                    fungible token pay out is a new mint which incurs the
                    associated costs such as minting and gas fees (9 ADA is
                    required and 2 ADA received with TBC token). It is
                    recommended to keep the staking for longer than a month in
                    order to save on such costs. The associated cost is one off
                    should a collector requests for TBC token to be sent into
                    his/her wallet after 7 months period when the whole staking
                    TBC tokens should be distributed to HHMC NFT holders.
                    However it is NFT holder’ responsibility to ensure that
                    staking carried out manually and requesting for TBC token
                    transfer into collector’ own ADA wallet to be done before
                    the program finishes in 7 months’ time.
                    <br />
                    <br />
                    Once staked collector will be able to see the accumulated
                    points on a daily basis as this is recorded in our database.
                    Manual staking (linking your HHMC NFT wallet into our
                    staking webpage) should be carried out at least on a monthly
                    basis in order to qualify for a month worth of points.
                    <br />
                    <br />
                    <p className="inline italic text-sm">Disclaimer:</p> As this
                    is a beta staking program, HHMC team do not guarantee or
                    take responsibility that all the facilities of this program
                    will work flawlessly at the outset. However, we will
                    endeavour our best to deliver high quality user experience
                    as this is rolled out over a period of 3 months in beta. Any
                    loss of staking points and or staking period is not
                    reimbursed due to the volume of HHMC NFTs available for
                    staking and human hours that it takes to look into each such
                    query. Staking does not guarantee any monetary or
                    shareholding value besides native token TBC which will be
                    part of HHMC ecosystem.
                  </div>
                  <div className="actions mt-4">
                    <button
                      className="p-2"
                      onClick={() => {
                        close();
                      }}
                    >
                      close
                    </button>
                  </div>
                </div>
              )}
            </Popup>
          </footer>
        </div>
      );
    }
    return (
      <div className="App min-h-screen mt-auto mb-auto flex flex-col justify-between">
        <div></div>
        <header className="App-header">
          <p className="mx-3">
            To start staking or check the status of your HHMC NFTs please verify
            your wallet!
          </p>
          <p className="mt-3 text-base">
            This process does not cost you any ADA!
          </p>
          <p className="mt-5 text-base text-[#7ce5e8]">
            {" "}
            Please select the wallet where you have HHMC-NFTs. Click on SIGN in
            the following pop-up.
          </p>

          {this.state.isLoading ? (
            <div className="lds-dual-ring flex-shrink justify-center "></div>
          ) : (
            <div className="flex flex-col mt-5 mx-5">
              <p className="text-base mb-2 mt-4">Detected wallets</p>

              {this.state.gero && (
                <button onClick={this.openGero}> Gero</button>
              )}
              {this.state.nami && (
                <button onClick={this.openNami}> Nami</button>
              )}
              {this.state.flint && (
                <button onClick={this.openFlint}> Flint</button>
              )}
              {this.state.ccvault && (
                <button onClick={this.openCCVault}> Eternl</button>
              )}
              {this.state.typhoon && (
                <button onClick={this.openTyphoon}> Typhoon</button>
              )}
              {this.state.card && (
                <button onClick={this.openCardWallet}>CardWallet</button>
              )}
              {this.state.walletCount == 0 && (
                <div>
                  <p className="text-base text-red-600">No Wallet detected!</p>
                  <p>
                    <br /> Have you enabled DApp support in your wallet
                    settings? <br /> If you are not sure if you have a wallet
                    please install one of the following wallets or their browser
                    extension:
                  </p>
                </div>
              )}
              <p className="text-base mb-2 mt-4">Not detected wallets</p>

              {!this.state.gero && (
                <button onClick={this.openGero} disabled>
                  Gero
                </button>
              )}
              {!this.state.nami && (
                <button onClick={this.openNami} disabled>
                  Nami
                </button>
              )}
              {!this.state.flint && (
                <button onClick={this.openFlint} disabled>
                  Flint
                </button>
              )}
              {!this.state.ccvault && (
                <button onClick={this.openCCVault} disabled>
                  Eternl
                </button>
              )}
              {!this.state.typhoon && (
                <button onClick={this.openTyphoon} disabled>
                  Typhoon
                </button>
              )}
              {!this.state.card && (
                <button onClick={this.openCardWallet} disabled>
                  CardWallet
                </button>
              )}
            </div>
          )}
        </header>
        <footer className="mb-3 mt-12 flex justify-center p-3">
          <a
            className="text-base mr-2"
            href="https://hhmc.io/termsampconditionshhmc"
          >
            Terms & Conditions
          </a>
          <Popup
            trigger={
              <p className="button text-base ml-2 cursor-pointer"> Legal </p>
            }
            modal
            nested
          >
            {(close) => (
              <div className="bg-[#1b1e24] mx-20 p-5 max-h-[70vh] overflow-y-auto">
                <div className="text-base mb-2">
                  {" "}
                  Introduction to HHMC staking program to earn TBC tokens{" "}
                </div>
                <div className="text-sm">
                  {" "}
                  HHMC NFT staking is a new way to earn native token TBC. It
                  lets HHMC NFT holders to keep their NFTs in their own wallet
                  and receive rewards for being a hodler. All without the need
                  to sell your NFT collection whilst keeping a full custody of
                  your NFT.
                  <br />
                  At the individual level, NFT staking can benefit collectors as
                  well as the developer team, as the overall supply tends to be
                  lower keeping the collectible price steady. But in a broader
                  context, NFT staking brings a new use case to NFTs that go
                  beyond the idea of collecting a digital art.
                  <br />
                  <br />
                  The minimum point to qualify for TBC token transfer into
                  collector’ own ADA wallet (such as Yoroi, Nami, CCvault and or
                  Daedalus) is 50 TBC tokens as the fungible token pay out is a
                  new mint which incurs the associated costs such as minting and
                  gas fees (9 ADA is required and 2 ADA received with TBC
                  token). It is recommended to keep the staking for longer than
                  a month in order to save on such costs. The associated cost is
                  one off should a collector requests for TBC token to be sent
                  into his/her wallet after 7 months period when the whole
                  staking TBC tokens should be distributed to HHMC NFT holders.
                  However it is NFT holder’ responsibility to ensure that
                  staking carried out manually and requesting for TBC token
                  transfer into collector’ own ADA wallet to be done before the
                  program finishes in 7 months’ time.
                  <br />
                  <br />
                  Once staked collector will be able to see the accumulated
                  points on a daily basis as this is recorded in our database.
                  Manual staking (linking your HHMC NFT wallet into our staking
                  webpage) should be carried out at least on a monthly basis in
                  order to qualify for a month worth of points.
                  <br />
                  <br />
                  <p className="inline italic text-sm">Disclaimer:</p> As this
                  is a beta staking program, HHMC team do not guarantee or take
                  responsibility that all the facilities of this program will
                  work flawlessly at the outset. However, we will endeavour our
                  best to deliver high quality user experience as this is rolled
                  out over a period of 3 months in beta. Any loss of staking
                  points and or staking period is not reimbursed due to the
                  volume of HHMC NFTs available for staking and human hours that
                  it takes to look into each such query. Staking does not
                  guarantee any monetary or shareholding value besides native
                  token TBC which will be part of HHMC ecosystem.
                </div>
                <div className="actions mt-4">
                  <button
                    className="p-2"
                    onClick={() => {
                      close();
                    }}
                  >
                    close
                  </button>
                </div>
              </div>
            )}
          </Popup>
        </footer>
      </div>
    );
  }
}
